import './App.css';
import SyntaxHighlighter from './syntaxHighlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useEffect, useState } from 'react';

const serverProd = "https://api.codespure.com";
const serverDev = "http://localhost:4000"

const App = () => {
  const [codeData, setCodeData] = useState();
  const { appLogo } = {
    "appLogo": "https://images.371digital.com/codesPureMiniLogo.svg"
  };
  const query = new URLSearchParams(window.location.search);
  const id = query.get('id');
  const withMaxHeight = query.get('withMaxHeight');

  useEffect(() => {
    fetch(`${serverProd}/api/codes/getCodeDetail?id=${id}`).then((res) => res.json()).then((res) => {
      if (res.code === 200) setCodeData(res.data);
    });
  }, [])

  return (
    <div className="App">
      {codeData && <div id="codeCardItem" className="codeCard">
        <div className="cardHeader">
          <div className="cardTitleContainer">
            <img src={codeData.user.image} className="userImage" />
            <div className="title">
              <xmp id="title">{codeData.title}</xmp>
            </div>
          </div>
          <img src={appLogo} className="appIcon" />
        </div>
        <SyntaxHighlighter
          code={codeData.code}
          showLineNumbers={false}
          language={codeData.language}
          wrapLongLines={true}
          className={`syntaxHighlighter ${withMaxHeight ? "withMaxHeight" : ""}`}
          style={vscDarkPlus}
        />
      </div>}
    </div>
  );
}

export default App;
