import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const SyntaxHighlighter = ({
    showLineNumbers = true,
    wrapLongLines = false,
    style = dracula,
    className,
    language,
    code,
}) => {
    return <ReactSyntaxHighlighter
        style={style}
        showLineNumbers={showLineNumbers}
        language={language}
        className={className}
        wrapLongLines={wrapLongLines}
    >
        {code}
    </ReactSyntaxHighlighter>
};

export default SyntaxHighlighter;